<template>
  <v-container grid-list-md>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Forms List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Filter" style="max-width:250px" hide-details single-line>
          <template v-slot:prepend-inner>
            <v-icon small style="padding-right:3px;transform:translateY(3px)">fal fa-search</v-icon>
          </template>
        </v-text-field>
      </v-toolbar>
      <v-list>
        <v-list-group v-for="(list, listIndex) in forms" :key="'list-' + listIndex">
          <template v-slot:activator>
            <v-list-item-title>{{ list.label }}</v-list-item-title>
          </template>
          <v-list-item v-for="(item, itemIndex) in list.items" :key="'list-item-' + itemIndex" :to="'/forms/' + item.shortName" dense>
            <v-list-item-title style="margin-left:2em">{{ item.name }}</v-list-item-title>
            <v-list-item-action v-if="!isLoggedIn && item.loginRequired">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">fas fa-lock</v-icon>
                </template>
                <span>Form requires login</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </v-container>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const isLoggedIn = computed(() => root.$store.state.loggedIn)
    const search = ref('')
    watch(search, () => {
      loadForms()
    })
    const forms = ref([])

    onMounted(() => {
      loadForms()
    })

    async function loadForms () {
      let query = {
        status: 'Active',
        inList: { $ne: false },
        $limit: 0,
        $sort: {
          group: 1,
          name: 1
        }
      }
      if (isLoggedIn.value) {
        query.$or = [
          { 'access.roles.0': { $exists: false } },
          { 'access.roles': { $in: user.value.roles } }
        ]
      } else {
        // Only load forms for which the user does not need to be logged in
        query['access.loginRequired'] = false
      }
      if (search.value !== '') query.$or = [{ name: { $regex: search.value, $options: 'i' } }, { group: { $regex: search.value, $options: 'i' } }]
      const { total } = await root.$feathers.service('forms/base').find({ query })
      query.$limit = 50
      const obj = {}
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        const { data } = await root.$feathers.service('forms/base').find({ query })
        for (let i = 0; i < data.length; i++) {
          let { group, name, shortName, _id } = data[i]
          if (!(group in obj)) obj[group] = []
          obj[group].push({ name, shortName, _id, visible: true, loginRequired: 'access' in data[i] ? data[i].access.loginRequired || false : false })
        }
      }
      forms.value = []
      for (const label in obj) {
        const idx = forms.value.length
        forms.value.push({
          label,
          items: []
        })
        for (let i = 0; i < obj[label].length; i++) {
          forms.value[idx].items.push(obj[label][i])
        }
      }
    }

    return {
      isLoggedIn,
      search,
      forms
    }
  }
}
</script>
